import '../../../assets/css/components/ui/loadingIndicator.css';

export interface LoadingIndicatorProps {
  absoluteCenter?: boolean
} 

export const LoadingIndicator = ( { absoluteCenter }: LoadingIndicatorProps ) => (
  <div className={`loading-indicator${absoluteCenter ? ' absolute' : ''}`}>
    <div className="dash"></div>
    <div className="dash"></div>
    <div className="dash"></div>
    <div className="dash"></div>
    <div className="dash"></div>
    <div className="dash"></div>
  </div>
);