import { IconType } from 'react-icons/lib';
import { RiArrowRightLine } from 'react-icons/ri';
import { NavLink, To } from 'react-router-dom';
import '../../assets/css/components/navigation/NavigationItem.css';

export interface NavigationItemProps {
  to: To,
  label: string,
  icon: IconType
}

export const NavigationItem = ( { to, label, icon: Icon } : NavigationItemProps ) => (
  <NavLink to={to} className="navigation-item button-click-effect">
    <Icon className='icon' />
    <span>{label}</span>
    <RiArrowRightLine className='arrow'/>
  </NavLink>
);