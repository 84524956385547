import { createSlice } from '@reduxjs/toolkit';
import { PayloadAction } from '@reduxjs/toolkit';

export type ThemeName = 'dark' | 'light';

export const localStorageTheme = 'theme';

export interface AppState {
  theme: ThemeName
}

const initialState : AppState = {
  theme: ( localStorage.getItem( localStorageTheme ) as ThemeName ) || 'dark'
};

export const appSlice = createSlice( {
  name: 'app',
  initialState,
  reducers: {
    setTheme: ( state, action: PayloadAction<ThemeName> ) => {
      state.theme = action.payload;
      localStorage.setItem( localStorageTheme, action.payload );
    }
  }
} );

export const { setTheme } = appSlice.actions;