import { useDispatch } from 'react-redux';
import { Toggle } from './Toggle';
import { setTheme } from '../../../state/slices/appSlice';
import { useTypedSelector } from '../../../state/store';


export const ThemeToggle = () => {
  
  const theme = useTypedSelector( state => state.app.theme );
  const dispatch = useDispatch();

  const toggleTheme = ( enabled: boolean ) => {
    dispatch( setTheme( enabled  ? 'light' : 'dark' ) );
  };
  
  return (
    <div className="theme-toggle" style={{ display: 'flex', justifyContent: 'space-between', padding: '16px' }}>
      <span>
        Theme: {theme}
      </span>
      <Toggle onToggle={toggleTheme} defaultValue={theme === 'light'}/>
    </div>
  );
};