import { BaseQueryFn } from '@reduxjs/toolkit/dist/query';
import axios, { AxiosError, AxiosRequestConfig } from 'axios';
import { Store } from '../state/store';
import { handleDates } from '../utils';
import { authService } from './authService';

// export const baseUrl = 'http://localhost:8443/admin';
export const baseUrl = 'https://api.crewww.io/v1/admin';

let store: Store;

export const servicesInjectStore = ( _store: Store ) => {
  store = _store;
};

export const authorizedAxiosInstance = axios.create( {
  baseURL: baseUrl,
  transformRequest: [
    ( data, headers ) => {
      if ( headers )
        headers['Authorization'] = `Bearer ${store.getState().auth.accessToken}`;
      return data;
    },
    ...( axios.defaults.transformRequest as [] ),
  ],
} );

authorizedAxiosInstance.interceptors.response.use( originalResponse => {
  if ( originalResponse?.data ) handleDates( originalResponse.data );
  return originalResponse;
} );

authorizedAxiosInstance.interceptors.response.use(
  undefined,
  async ( error: AxiosError & { config: { _retry?: boolean } } ) => {
    const originalRequest = error.config;
    if ( error.response?.status === 401 && !originalRequest._retry ) {
      originalRequest._retry = true;
      await authService.refresh();
      return authorizedAxiosInstance( originalRequest );
    }
  }
);

export const axiosBaseQuery: BaseQueryFn<
  {
    url: string;
    method: AxiosRequestConfig['method'];
    data?: AxiosRequestConfig['data'];
    params?: AxiosRequestConfig['params'];
  },
  unknown,
  unknown
> = async ( { url, method, data, params } ) => {
  try {
    const result = await authorizedAxiosInstance( {
      url: baseUrl + url,
      method,
      data,
      params,
    } );
    return { data: result.data };
  } catch ( axiosError ) {
    const err = axiosError as AxiosError;
    return {
      error: { status: err.response?.status, data: err.response?.data },
    };
  }
};
